<div class="mobile-menu-selector" (click)="showMobileMenu()">
    <i class="fa-solid fa-bars"></i>
</div>



<div class="mobile-menu" @slideInOut  *ngIf="showMenu">

    <div class="mm-outer" [@fadeMenuBody]="menuBodyFadeState">
        <div class="mm-inner">

            <div class="mm-close" (click)="closeMenu()">
                <i class="fa-solid fa-xmark"></i>
            </div>

            <div class="mm-menu-wrap">

                <div>
                    <div class="mm-logo" (click)="navigateHome()">
                        <img src="assets/logo/canary.png">
                    </div>


                    <div class="mm-m-w-links">
                        <div class="mm-menu-item" (click)="userNavigated()">
                            <a [routerLink]="['/home']" routerLinkActive="menu-active">
                                <div class=" mm-menu-text">
                                    <i class="fa-solid fa-layer-group"></i> Home
                                </div>
                            </a>
                        </div>
        
                        <div class="mm-menu-item" (click)="userNavigated()">
                            <a [routerLink]="['/profile/']" routerLinkActive="menu-active">
                                <div class=" mm-menu-text">
                                    <i class="fa-solid fa-user"></i>  Profile
                                </div>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="mm-m-w-logout">
                    <!-- <app-button-large (click)="logOut()">
                        Log out
                    </app-button-large> -->
                </div>
                
            </div>
        </div>
    </div>

</div>