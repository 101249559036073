	import { HttpClient } from '@angular/common/http';
	import { Injectable } from '@angular/core';
	import { AppsettingsService } from '../app-settings/app-settings.service';
	import { GlobalService } from '../global/global.service';

	@Injectable({
	providedIn: 'root'
	})
	export class ProfileService {

	constructor(
		private AppsettingsService: AppsettingsService,
		private HttpClient: HttpClient,
		private GlobalService: GlobalService
	) { }


	/*
		Used to get information about a logged in profile
	*/
	getAccountInformation() {
		return new Promise((resolve, reject) => {
			let url = this.AppsettingsService.getApiServerUrl() + 'profile/get-account-information';
			return this.HttpClient.get(url).subscribe((response: any) => {
				return resolve(this.GlobalService.httpCheckReturn(response));
			});
		});
	}

	/*
		Used to update a logged in users profile
		Should probably not be able to change a users username?
	*/
	updateAccountInformation(data: any) {
		return new Promise((resolve, reject) => {
			let url = this.AppsettingsService.getApiServerUrl() + 'profile/update-account-information';
			return this.HttpClient.post(url, data).subscribe((response: any) => {
				return resolve(this.GlobalService.httpCheckReturn(response));
			});
		});
	}

	/*
		Used to update a users password, requires both the old and new password to be sent,
		this is because we want to verify that it is the real user that is trying to change the password.
	*/
	updatePassword(data: any) {
		return new Promise((resolve, reject) => {
			let url = this.AppsettingsService.getApiServerUrl() + 'profile/update-password';
			return this.HttpClient.post(url, data).subscribe((response: any) => {
				return resolve(this.GlobalService.httpCheckReturn(response));
			});
		});
	}

	}
