import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../auth/services/auth/auth.service';
import { animate, state, style, transition, trigger } from '@angular/animations';


@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
  animations: [
    trigger('fadeMenuBody', [
        state('fadeIn', style({
            opacity: 1
        })),
        state('fadeOut', style({
            opacity: 0
        })),
        transition('fadeIn => fadeOut', [
            animate('0.5s')
        ]),
        transition('fadeOut => fadeIn', [
            animate('0.5s')
        ]),
    ]),
    trigger('slideInOut', [
        transition(':enter', [
            style({transform: 'translateX(100%)'}),
            animate('200ms ease-in', style({transform: 'translateX(0%)'}))
        ]),
        transition(':leave', [
            animate('200ms ease-in', style({transform: 'translateX(-100%)'}))
        ])
      ])
],
})
export class MobileMenuComponent implements OnInit {

  constructor(
    private Router:Router,
    private AuthService: AuthService
  ) { }

  ngOnInit(): void {
  }

  //If true, the menu will be visible
  showMenu: boolean = false;

  //Log out the user
  async logOut() {

    this.AuthService.logout();
  }
  

  //Called when the user navigates, hides the menu
  userNavigated() {
    this.showMenu = false;
  }

  menuBodyFadeState = 'fadeOut';

  //Toggles the menu value whenever presed
  showMobileMenu() {

    this.showMenu = !this.showMenu 

     // Menu is about to be shown
     if ( this.showMenu ) {

      setTimeout(() => {
          this.menuBodyFadeState = 'fadeIn';
      }, 200);
  
    // Menu is about to be hidden
    } else {
        this.menuBodyFadeState = 'fadeOut';
    }
  }

  //Close the mobile menu
  closeMenu( ) {
    this.showMenu = false;
  }
  
  //Pressing the logo navigates home and closes the menu
  navigateHome() {
    this.Router.navigateByUrl('/');
    this.showMenu = false;
  }



}
